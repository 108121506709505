var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex justify-center" },
    [
      _c(
        "v-banner",
        { staticClass: "trial-banner text-center d-flex align-baseline" },
        [
          _c("span", { staticClass: "primarybase--text h6--text" }, [
            _vm._v(" CREATE AN ACCOUNT TO START YOUR "),
            _c("strong", { staticClass: "font-800" }, [
              _vm._v(" 30-DAY FREE TRIAL! "),
            ]),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "ml-4 px-8",
              attrs: {
                outlined: "",
                color: "primarybase",
                href: "https://www.searchadsmaven.com/request-a-demo/",
              },
            },
            [_vm._v(" Start with a Quick Demo")]
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "d-flex" },
        [
          _c(
            "v-card",
            { staticClass: "signin-outer primarybase margin-auto" },
            [
              _c("v-img", {
                attrs: {
                  "max-width": "164",
                  src: require("../assets/images/poweredby-light.svg"),
                },
              }),
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.signupOry.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "pt-15 pb-12" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "position-relative py-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("label", { staticClass: "sticky-label" }, [
                                _vm._v("Email address"),
                              ]),
                              _c("v-text-field", {
                                staticClass: "rounded-0",
                                attrs: {
                                  required: "",
                                  outlined: "",
                                  "append-icon": "mdi-check",
                                  dense: "",
                                  rules: _vm.emailRules,
                                  disabled: !_vm.emailEnable,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.close()
                                  },
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "position-relative py-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("label", { staticClass: "sticky-label" }, [
                                _vm._v("Create Password"),
                              ]),
                              _c("v-text-field", {
                                staticClass: "rounded-0",
                                attrs: {
                                  min: "6",
                                  "append-icon": _vm.toggle
                                    ? "mdi-eye-outline"
                                    : "mdi-eye-off-outline",
                                  type: _vm.toggle ? "text" : "password",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  name: "password",
                                  rules: _vm.nameRules,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.toggle = !_vm.toggle
                                  },
                                  click: function ($event) {
                                    return _vm.close()
                                  },
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "secondarybase white--text w-100 font-14 primarybase--text",
                                  attrs: {
                                    type: "submit",
                                    block: "",
                                    depressed: "",
                                    large: "",
                                    disabled: _vm.enableLogin || !_vm.valid,
                                    value: "password",
                                    name: "method",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.signupOry()
                                    },
                                  },
                                },
                                [_vm._v("SIGN UP")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showmsg || _vm.msgModel
                ? _c(
                    "v-card",
                    {
                      staticClass:
                        "error-dialog text-center pa-6 pb-12 pt-8 d-flex flex-column justify-center",
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "close",
                          attrs: { color: "white" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                      _c("v-icon", { attrs: { color: "white", large: "" } }, [
                        _vm._v("mdi-alert-circle-outline"),
                      ]),
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "h6--text white--text d-flex justify-center Whitney-font",
                        },
                        [_vm._v(" " + _vm._s(_vm.msgTitle) + " ")]
                      ),
                      _vm.showmsg
                        ? _c(
                            "v-card-text",
                            {
                              staticClass:
                                "body--text-2 white--text text-left Whitney-font pa-0",
                            },
                            [
                              _vm._v(
                                " This sign-up form only accepts enterprise email addresses. Please provide an enterprise email address that is associated with a corporate or enterprise domain "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.msgModel && _vm.mailto
                        ? _c(
                            "v-card-text",
                            {
                              staticClass:
                                "body--text-2 white--text text-left Whitney-font pa-0",
                            },
                            [
                              _vm._v(
                                " This email domain is already in use with an active account, please contact your company's Search Ads Maven administrator to be added to the account. Or, contact our support staff at "
                              ),
                              _c("span", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "mailto:support@searchadsmaven.com",
                                    },
                                  },
                                  [_vm._v("support@searchadsmaven.com")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.msgModel
                        ? _c(
                            "v-card-text",
                            {
                              staticClass:
                                "body--text-2 white--text text-left Whitney-font pa-0",
                            },
                            [_vm._v(" " + _vm._s(_vm.message) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "v-login-info" }, [
                _c(
                  "p",
                  { staticClass: "mt-0 white--text font-18 medium-font" },
                  [
                    _vm._v(" Do you have an account ? "),
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.goToLogin()
                          },
                        },
                      },
                      [_vm._v("Login")]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c("v-img", {
            staticClass: "bg-logo",
            attrs: { src: require("../assets/images/bg-logo.svg") },
          }),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "hide-overlay": "", persistent: "", width: "300" },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "headline text-subtitle-1 d-flex justify-space-between px-5 py-3 v-modal-header",
                        },
                        [_vm._v(" Processing... ")]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "cust" },
                        [
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "text-center" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }