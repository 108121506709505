<template flex>
  <div class="login d-flex justify-center">
    <v-banner class="trial-banner text-center d-flex align-baseline">
      <span class="primarybase--text h6--text">
        CREATE AN ACCOUNT TO START YOUR
        <strong class="font-800"> 30-DAY FREE TRIAL! </strong>
      </span>
      <v-btn outlined color="primarybase" class="ml-4 px-8" href="https://www.searchadsmaven.com/request-a-demo/">
        Start with a Quick Demo</v-btn
      >
    </v-banner>
    <v-main class="d-flex">
      <v-card class="signin-outer primarybase margin-auto">
        <v-img max-width="164" src="../assets/images/poweredby-light.svg"></v-img>
        <v-form v-model="valid" lazy-validation @submit.prevent="signupOry" ref="form">
          <v-container>
            <v-row class="pt-15 pb-12">
              <v-col cols="12" class="position-relative py-0">
                <label class="sticky-label">Email address</label>
                <v-text-field
                  v-model.trim="email"
                  required
                  outlined
                  class="rounded-0"
                  append-icon="mdi-check"
                  dense
                  :rules="emailRules"
                  @click="close()"
                  :disabled="!emailEnable"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="position-relative py-0">
                <label class="sticky-label">Create Password</label>
                <v-text-field
                  v-model="password"
                  min="6"
                  :append-icon="toggle ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="toggle ? 'text' : 'password'"
                  @click:append="toggle = !toggle"
                  @click="close()"
                  required
                  outlined
                  class="rounded-0"
                  dense
                  name="password"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-btn
                  type="submit"
                  block
                  depressed
                  large
                  :disabled="enableLogin || !valid"
                  @click="signupOry()"
                  class="secondarybase white--text w-100 font-14 primarybase--text"
                  value="password"
                  name="method"
                  >SIGN UP</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <!-- error message dialog  -->
        <v-card class="error-dialog text-center pa-6 pb-12 pt-8 d-flex flex-column justify-center" v-if="showmsg || msgModel">
          <v-icon color="white" @click="close()" class="close">mdi-close</v-icon>

          <v-icon color="white" large>mdi-alert-circle-outline</v-icon>
          <v-card-title class="h6--text white--text d-flex justify-center Whitney-font">
            {{ msgTitle }}
          </v-card-title>

          <v-card-text class="body--text-2 white--text text-left Whitney-font pa-0" v-if="showmsg">
            This sign-up form only accepts enterprise email addresses. Please provide an enterprise email address that is associated with a
            corporate or enterprise domain
          </v-card-text>
          <v-card-text class="body--text-2 white--text text-left Whitney-font pa-0" v-if="msgModel && mailto">
            This email domain is already in use with an active account, please contact your company's Search Ads Maven administrator to be
            added to the account. Or, contact our support staff at
            <span><a href="mailto:support@searchadsmaven.com">support@searchadsmaven.com</a></span>
          </v-card-text>
          <v-card-text class="body--text-2 white--text text-left Whitney-font pa-0" v-if="msgModel">
            {{ message }}
          </v-card-text>
        </v-card>

        <div class="v-login-info">
          <p class="mt-0 white--text font-18 medium-font">
            Do you have an account ?
            <a class="secondary--text" href="#" @click="goToLogin()">Login</a>
          </p>
        </div>
      </v-card>
      <v-img class="bg-logo" src="../assets/images/bg-logo.svg"></v-img>

      <div class="text-center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card>
            <v-card-title class="headline text-subtitle-1 d-flex justify-space-between px-5 py-3 v-modal-header">
              Processing...
            </v-card-title>
            <v-card-text class="cust">
              <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="text-center">
      </div>
    </v-main>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue';
import * as moment from 'moment';
import { SignUpService } from '@services/signup.service';
import LogRocket from 'logrocket';
import { BLOCKED_DOMAINS } from 'src/utils/constants';

export default Vue.extend({
  name: 'SignUp',
  components: {},

  data() {
    return {
      showmsg: false,
      msg: '',
      enableSS: false,
      mailto: false,
      blDomains: BLOCKED_DOMAINS,
      id: '',
      emailEnable: true,
      email: null,
      password: null,
      inputEmail: 'Input your E-Mail Id here',
      inputPassword: 'Input your password here',
      passwordType: '',
      toggle: false,
      loading: false,
      valid: false,
      msgModel: false,
      message: ``,
      msgTitle: '',
      login: false,
      enableLogin: false,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'E-mail must be valid',
        (v) => !/\s/.test(v) || 'Email cannot contain spaces',
        (v) =>
          !this.emailEnable ||
          (v && v.includes('@') && !this.blDomains.includes(v.split('@')[1].toLowerCase())) ||
          'Please use an enterprise email account',
      ],
      nameRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length >= 9) || 'Must be atleast 9 characters',
        (v) => /[A-Z]/.test(v) || 'Must contain atleast 1 capital letter',
        (v) => /\d/.test(v) || 'Must contain atleast 1 number',
        (v) => /[^A-Za-z 0-9]/.test(v) || 'Must contain special characters', // by checking presence of any  character other than numbers and aplphabets
        (v) => !/\s/.test(v) || 'Cannot contain spaces',
      ],
    };
  },
  watch: {
    email: function (v) {
      if (!v) {
        // Email is empty
        this.showmsg = false;
      } else if (this.emailEnable && v && v.includes('@') && this.blDomains.includes(v.split('@')[1].toLowerCase())) {
        this.showmsg = true;
        this.msgTitle = 'Error';
      }
      if (this.message != '') {
        this.msgModel = false;
      }
    },
  },
  methods: {
    close() {
      this.showmsg = false;
      this.msgModel = false;
    },
    async signupOry() {
      this.$refs.form.validate();
      if (!this.email || !this.password) {
        return;
      }
      if (!this.valid) {
        return;
      }
      this.loading = true;
      this.enableLogin = true;
      const email = this.email.toLowerCase();
      await SignUpService.signupOry({
        Email: email,
        Password: this.password,
      })
        .then((res) => {
          this.mailto = false;
          if (res.code == 0) {
            if (this.id != '') {
              this.login = true;
              this.loading = false;
              this.msgModel = true;
              this.msgTitle = 'Success';
              this.message = 'Signup successfull. Redirecting to Login';
              setTimeout(() => {
                return this.$router.push('/login');
              }, 2000);
            } else {
              this.login = true;
              this.loading = false;
              this.msgModel = true;
              this.msgTitle = 'Success';
              this.message = 'Please check your mail to verify and login';
            }
          } else if (res.code == 1 || res.code == 7) {
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error';
            this.message = 'Email already exists. Please try login';
            this.enableLogin = false;
          } else if (res.code == 5) {
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error';
            this.message = 'Server Error!!! Plea' + res.code + 'e try later';
            this.enableLogin = false;
          } else if (res.code == 6) {
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error';
            this.message = 'Weak password!!! Please try atleast 9 characters with 1 capital letter, 1 numeric and 2 special character';
            this.enableLogin = false;
          } else if (res.code == 9) {
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error';
            this.message = 'Registration is closed to public for now. Please come back later';
            this.enableLogin = false;
          } else if (res.code == 27) {
            this.loading = false;
            this.mailto = true;
            this.msgModel = true;
            this.msgTitle = 'Error';
            this.message = ``;
            this.enableLogin = false;
          } else if (res.code == 37) {
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error';
            this.message = `This email domain is not allowed`;
            this.enableLogin = false;
          } else {
            this.loading = false;
            this.msgModel = true;
            this.msgTitle = 'Error';
            this.message = 'Server Error!!! Please try later';
            this.enableLogin = false;
          }
        })
        .catch((err) => {
          this.mailto = false;
          this.loading = false;
          this.msgModel = true;
          this.msgTitle = 'Error';
          this.message = 'Server Error!!! Please try later';
          this.enableLogin = false;
        });
    },
    goToLogin() {
      this.login = false;
      this.msgModel = false;
      return this.$router.push('/login');
    },
    async getBLDomains() {
      const blDomains = await SignUpService.getBLDomains();
      this.blDomains = blDomains;
    },
    async getSS() {
      const enableSS = await SignUpService.getSS();
      this.enableSS = enableSS;
      if (!this.enableSS) window.location.href = 'https://www.searchadsmaven.com/join-the-beta/';
    },
  },
  mounted() {
    this.$root.$emit('signInOpen');
    this.getBLDomains();
    this.valid = false;
    const id = this.$route.query.id ? this.$route.query.id : '';
    if (
      id &&
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        id
      )
    ) {
      this.id = id;
      this.email = id;
      this.emailEnable = false;
    } else {
      this.id = '';
      this.email = '';
      this.emailEnable = true;
    }
    if (window.location.host == 'app.searchadsmaven.com') {
      LogRocket.init('f59ovk/search-ads-maven', {
        network: {
          requestSanitizer: (request) => {
            if (request.url.toLowerCase().indexOf('onboard/save') !== -1) {
              request.body = null;
            }
            if (request.url.toLowerCase().indexOf('auth/signup-ory') !== -1) {
              request.body = null;
            }
            if (request.url.toLowerCase().indexOf('self-service/login') !== -1) {
              request.body = null;
            }
            return request;
          },
        },
      });
    }
  },
});
</script>
<style lang="scss">
.cust {
  text-align: center !important;
  padding: 10px 24px 20px !important;
}

.v-main {
  height: 100vh;
}

@media only screen and (max-width: 601px) {
  .v-application .v-main {
    padding-left: 0px !important;
  }
}
</style>
